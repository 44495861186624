import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./mail.css"
import { useState } from 'react';
import { MdDone } from "react-icons/md";

const initialState={
    user_email:"",
    message:"",
}

export const ContactUs = () => {
    const form = useRef();
    const [formData,setFormData] = useState (initialState)
    const [sent,setSent] = useState (false)
    const sendEmail = (e) => {
      e.preventDefault();
      
      setFormData(initialState)
      setSent(true)
      setTimeout(()=>{
        setSent(false)
      },5000)
    
      emailjs.sendForm('service_50t9uc7', 'template_vl143hp', form.current, '_6fBoYT0vtfszqy0k')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const handleChange=(event)=>{
        const {name,value}=event.target
        setFormData((prevData)=>({
            ...prevData,
            [name]:value
        }))
    }
  
    return (
        <div>
         <div className='contact-header'>
            <h1 >თქვენი პრობლემა</h1> 
        </div>
        <div className='form-full'>
         <div className='form-main'>
                <form id='form' ref={form} onSubmit={sendEmail}>
                    <div className='form-field'>
                    <label  for="email-input"></label>
                        <input value={formData.user_email} onChange={handleChange}  type="email"  id="email" name="user_email"  placeholder="თქვენი ელფოსტა"  required/>
                    </div>
                    <div className='form-field'>
                    <label></label>
                        <textarea value={formData.message} onChange={handleChange} type="text"  name="message" id='text-input'  placeholder="თქვენი პრობლემა ან შეკითხვა ჩაწერეთ აქ" cols="30" rows="10"  required/>
                    </div>
                    <div className='form-field'>
                        <input   className='contact-btn' type="submit" value="გაგზავნა"  />
                    </div>
                </form>
                <div className='confident'>
                    <p >ანონიმურობა დაცულია!</p>     
                    {sent && <MdDone size={30} style={{color:"green"}}/>}           
                </div>
         </div>
        </div>
      </div>



    );
  };