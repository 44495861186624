import React from 'react'
import "./App.css"
import Text from './components/text/Text';
import Navbar from './components/navbar/Navbar';

import Footer from './components/footer/Footer';
import { ContactUs } from './components/mail/Mail';


const App = () => {
  return (
<>
    <Navbar/>
    <Text/>
    <ContactUs/>
    <Footer/>
   
</>
  )
}

export default App
