import React from 'react'
import './text.css'

const Text = () => {
  return (
    <div className='text-img'>
      <div className='text' >
      <h3 className='text-item'>1.ნათლად და ლაკონურად ჩამოაყალიბეთ თქვენი პრობლემა;</h3>
      <h3 className='text-item'>2.ტექსტი შეიყვანეთ ქართული შრიფტით, სხვა შრიფტით შეყვანილი ტექსტი არ განიხილება;</h3>
      <h3 className='text-item'>3.პრობლემების თემატიკა შეუზღუდავია;</h3>
      <h3 className='text-item'>4.პასუხს მიიღებთ ელექტრონულ ფოსტაზე, 24 საათის განმავლობაში;</h3>
      <h3 className='text-item'>5.ჩვენ არ ვჭრით თქვენს პრობლემას, გეხმარებით თავად გადაჭრათ იგი.</h3>
      </div>
      {/* <div id="top-ge-counter-container" data-site-id="47636"></div>
                <script async src="//counter.top.ge/counter.js"></script> */}
    </div>
    
  )
}

export default Text
