import React from 'react'
import './footer.css'
import { RiCopyrightLine } from "react-icons/ri";

const Footer = () => {
  return (
    <div className='footer' >
      <p className='copyright'><RiCopyrightLine className='copy-icon' /> problema.ge</p>            
    </div>
  )
}

export default Footer
