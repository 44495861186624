import React from 'react'
import './navbar.css'
import logo from './image/logo.svg'



const Navbar = () => {
  return (
    
    <div>  
      <img className='logo-img' src={logo} alt="" />
    </div>
  )
}

export default Navbar
